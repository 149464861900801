<template>
    <div>
        <v-row v-if="!permissions.can_download">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Promoter Listing</p>
                <p class="text-title mb-0">You do not have permission to view promoter listing</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_download">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-form lazy-validation ref="form" v-model="valid">
                        <v-sheet color="white" elevation="0" class="pa-0">
                            <p class="text-h6 mb-2">Promoter Attendance Report</p>
                             <v-row>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-autocomplete dense label="Projects*" class="my-3" :items="projects" :rules="rules.project" v-model="filters.project"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-dialog persistent width="320px" ref="startDialog" :return-value.sync="filters.start_date" v-model="startDateDialog">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="Start Date" class="mt-3" :rules="rules.start_date" :error-messages="errors.start_date" v-on="on" v-bind="attrs" v-model="filters.start_date"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable color="primary" v-model="filters.start_date">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="startDateDialog=false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.startDialog.save(filters.start_date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-dialog persistent width="320px" ref="endDialog" :return-value.sync="filters.end_date" v-model="endDateDialog">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="End Date" class="mt-3" :rules="rules.end_date" :error-messages="errors.end_date" v-on="on" v-bind="attrs" v-model="filters.end_date"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable color="primary" v-model="filters.end_date">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="endDateDialog=false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.endDialog.save(filters.end_date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openDownloadDialog">
                                        <v-icon small left>mdi-download</v-icon> Download
                                    </v-btn>
                                    <v-btn small class="mt-2 elevation-0" :ripple="false" @click='reset'>
                                        <v-icon small left>mdi-undo</v-icon> Reset
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-form>
                </v-col>
            </v-row>
        </template>
        <promoter-attendance-listing-download ref="promoterAttendanceListingDownloadDialog" @downloaded="openLoadingDialog"></promoter-attendance-listing-download>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
    </div>
</template>


<script>
import PromoterAttendanceListingDownload from './PromoterAttendanceListingDownload.vue'
import SimpleActionDialog from '../../../../shared/SimpleActionDialog.vue'

export default {
    name: 'PromoterAttendanceListing',
    components: {
        simpleActionDialog: SimpleActionDialog,
        promoterAttendanceListingDownload: PromoterAttendanceListingDownload,
    },
    data() {
        return {
            valid: true,
            items: [],
            projects: [],
            startDateDialog: false,
			endDateDialog: false,
            filters: {
                start_date: '',
                end_date: ''
            },
            object: {},
            permissions: {
                can_download: false
            },
            rules: {
                project: [
                    (value) => !!value || 'Project is required'
                ]
            },
            errors:{}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        },
        promoterAttendanceListingDownloadDialog() {
            return this.$refs.promoterAttendanceListingDownloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.$store.dispatch('epanel/report/getPromoterListing').then((response) => {
                this.permissions = response.data.permissions
                this.projects = response.data.projects
            })
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download && this.form.validate()){
                var object = { project: this.filters.project, start_date: this.filters.start_date, end_date: this.filters.end_date}
                this.promoterAttendanceListingDownloadDialog.updateObject(object)
                this.promoterAttendanceListingDownloadDialog.open()
            }
        },
        reset: function() {
            this.filters = { project: '', start_date: '', end_date: '' }
            this.get()
        }
    }
}

</script>